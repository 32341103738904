import {ajax} from './axios'
import qs from 'qs'
const scope = 'server'
export const loginByUsername = (username, password) => {
    const dataObj = { 'mobile': username, 'password': password }
    return ajax({
        url: '/user/login',
        headers: {
            isToken: false,
            // Authorization: basicAuth
        },
        method: 'post',
        data: dataObj
    })
}
/**
 * 忘记密码
 */
 export const passwordReset = (form) => {
    return ajax({
        url: '/user/password/reset',
        method: 'post',
        data: form,
        headers: {
            isToken: false,
        },
    })
}
/**
 * 身份列表
 */
 export const getIdentity = (form) => {
    return ajax({
        url: '/user/identity',
        method: 'post',
        data:form,
        headers: {
            isToken: true,
        },
    })
}
//获取KEY 
export const getQwKey = () => {
    return ajax({
        url: `/account/qw_auth`,
        method: 'get',
        headers: {
            isToken: false,
        },
    })
}
//获取二维码
export const getQwCode = (opt) => {
    return ajax({
        url: `/account/qw_code`,
        method: 'get',
        headers: {
            isToken: false,
        },
        params:opt
    })
}
//获取二维码状态
export const getQwState = (opt) => {
    return ajax({
        url: `/account/qw_state/${opt.key}`,
        method: 'get',
        headers: {
            isToken: false,
        },
    })
}

/**
 * 注册账号
 */
export const registerUser = (userInfo) => {
    return ajax({
        url: '/register/user',
        method: 'post',
        data: userInfo
    })
}



/**
 * 发送短信
 */
export const mobileMessage = (form) => {
    return ajax({
        url: '/user/send',
        method: 'get',
        params: form,
        headers: {
            isToken: false,
        },
    })
}
//获取登录token
export const getToken = (obj) => {
    return ajax({
        url: `/user/token`,
        method: 'post',
        headers: {
            isToken: true,
        },
        data:obj
    })
}
//获取登录信息
export const getInfoDetail = (obj) => {
    return ajax({
        url: `/user/info/detail`,
        method: 'get',
        headers: {
            isToken: true,
        },
    })
}
//我的应用
export const getAppMy = (data) => {
    return ajax({
        url: `/home/my`,
        method: 'get',
        params:data,
        headers: {
            isToken: true,
        },
        params:data
    })
}
//首页应用
export const getApps = (data) => {
    return ajax({
        url: `/home/apps`,
        method: 'get',
        headers: {
            isToken: true,
        },
        params:data
    })
}
//登录应用
export const getHomeLogin = (obj) => {
    return ajax({
        url: `/home/${obj.id}/login`,
        method: 'get',
        headers: {
            isToken: true,
        },
    })
}
//备课列表
export const getBkList = (params) => {
    return ajax({
        url: `/bk`,
        method: 'get',
        params:params,
        headers: {
            isToken: true,
        },
    })
}
// 我的收藏 
export const teachCollect = (opt) => {
    return ajax({
        url: `/teach/collect`,
        method: 'get',
        params:opt,
        headers: {
            isToken: true,
        },
    })
}
// 观看记录 
export const teachRecord = (opt) => {
    return ajax({
        url: `/teach-record/mobile`,
        method: 'get',
        params:opt,
        headers: {
            isToken: true,
        },
    })
}
//资源列表
export const resourceList = (opt) => {
    return ajax({
        url: `/teach`,
        method: 'get',
        params:opt,
        headers: {
            isToken: true,
        },
    })
}
//获取审核列表
export const checkList = (opt) => {
    return ajax({
        url: `/teach-verify`,
        method: 'get',
        params:opt,
        headers: {
            isToken: true,
        },
    })
}
// 删除观看记录 
export const teachRecordDel = (opt) => {
    return ajax({
        url: `/teach-record/${opt.id}`,
        method: 'delete',
        headers: {
            isToken: true,
        },
    })
}
//获取作业
export const getHomework = (opt) => {
    return ajax({
        url: `/homework/recent/tea5`,
        method: 'get',
        params:opt,
        headers: {
            isToken: true,
        },
    })
}
//获取相关推荐
export const getVideoRecommend = (opt) => {
    return ajax({
        url: `/teach/video-recommend`,
        method: 'get',
        params:opt,
        headers: {
            isToken: true,
        },
    })
}
//获取新闻列表
export const getTeachNews = (opt) => {
    return ajax({
        url: '/teach/news',
        method: 'get',
        params:opt,
        headers: {
            isToken: false,
        },
    })
}
//获取学校列表
export const getSchoolUrl = (opt) => {
    return ajax({
        url: '/teach/school-url',
        method: 'get',
        params:opt,
        headers: {
            isToken: false,
        },
    })
}
export const teachHot = (params) => {
    return ajax({
        headers: {
            isToken: false,
        },
        url:'/teach/hot',
        method: 'get',
        params:params
    })
}
export const teachNew = (params) => {
    return ajax({
        headers: {
            isToken: false,
        },
        url:'/teach/new',
        method: 'get',
        params:params
    })
}
export const userLogout = (params) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:'/user/logout',
        method: 'delete',
    })
}
export const userBind = (params) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:'/user-bind',
        method: 'get',
    })
}
export const userBindKey = (params) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/user-bind/${params.key}`,
        method: 'put',
    })
}
export const userBindAdd = (params) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:'/user-bind',
        method: 'post',
        data:params
    })
}
// 站内信
export const websiteMessage = (params) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:'/website-message',
        method: 'get',
    })
}
// 站内信已读
export const websiteMessageRead = (params) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/website-message/${params.websiteMessageId}/read`,
        method: 'put',
    })
}
export const bindMobile = (params) => {
    return ajax({
        headers: {
            isToken: false,
        },
        url:`/account/bind/mobile`,
        method: 'put',
        params:params
    })
}
// 智能体登录
export const zntLogin = (params) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:'/account/login/zntpt',
        method: 'get',
    })
}