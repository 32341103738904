import { basic_static, domain_static,basic_api_url} from '@/api/axios';
import { getStage, getGrade, getSubject, getBookChapter, getKnowLedgeTree,getNewEdition,getNewBook,getNewChapter,getNewNode,getNewNodeChild,getNewKnowledge } from '@/api/base'
import {getTreeData} from '@/util/index.js'
const user = {
    state: {
        basic_static:'https://cdn.tqxxkj.cn/static/',//静态资源
        domain_static:'https://xxkj.glzhxx.com/studyspace',//域名
        basic_api_url:basic_api_url,//接口
        login_mess:{},
        stage_list:[], //学段
        grade_list:[], //年级
        subject_list:[], //学科
        book_chapter_list:[], // 册章（课)节
        knowledge_list:[],  //知识点
        grade_list_index:[], //年级
        subject_list_index:[], //学科
        book_chapter_list_index:[], // 册章（课)节
        knowledge_list_index:[],  //知识点
        access_token: '',
        edition_list:[],
        edition_list_index:[],
        book_list_index:[],
        book_list:[],
        chapter_list:[],
        chapter_list_index:[],
        node_list:[],
        node_list_index:[],
        node_child_list:[],
        node_child_list_index:[],
        login_dialog:false,
        menu_list:[	{
            id: 1,
            title: "首页",
            url: "/homepage/index",
            show:true,
            type:'current'
        },
        {
            id: 2,
            title: "数字化教学",
            url: "/space/my",
            show:true,
            type:'current'
        },
        {
            id: 3,
            title: "资源中心",
            url: "/resource/index",
            show:true,
            type:'current'
        },
        {
            id: 4,
            title: "综合素质评价",
            show:true,
            type:'open',
            url: "https://www.glzhxx.com/zhszpj/admin/#/login",
        },
        {id:5,title:'OA应用中心',url:'/application',show:true,type:'current',},
        { id: 6, title: "双线课堂",show:true, },
        { id: 7, title: "数据管理", url: "https://dc.glzhxx.com/#/landpage",show:true,type:'open', },
        { id: 8, title: "考核管理", url: "https://xxkj.glzhxx.com/groupsch/#/login",show:true,type:'open', },],
        isPc:true,
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, access_token) => {
            state.access_token = access_token
          },
        //设置公共域名连接
        basic_static: (state, obj) => {
            state.basic_static = obj;
        },
        //处理基础的线上地址链接
        domain_static: (state, obj) => {
            state.domain_static = obj;
        },
        basic_api_url: (state, obj) => {
            state.basic_api_url = obj;
        },
        set_login_mess: (state, obj) => {
            state.login_mess = obj;
        },
        set_menu_list: (state, obj) => {
            state.menu_list = obj;
        },
        set_login_dialog: (state, obj) => {
            state.login_dialog = obj;
        },
        set_stage_list:(state, arr) => {
            state.stage_list = arr
        },
        set_grade_list:(state, arr) => {
            state.grade_list = arr
        },
        set_subject_list:(state, arr) => {
            state.subject_list = arr
        },
        set_book_chapter_list:(state, arr) => {
            state.book_chapter_list = arr
        },
        set_knowledge_list:(state, arr) => {
            state.knowledge_list = arr
        },
        set_grade_list_index:(state, arr) => {
            state.grade_list_index = arr
        },
        set_subject_list_index:(state, arr) => {
            state.subject_list_index = arr
        },
        set_book_chapter_list_index:(state, arr) => {
            state.book_chapter_list_index = arr
        },
        set_knowledge_list_index:(state, arr) => {
            state.knowledge_list_index = arr
        },
        set_edition_list_index:(state, arr) => {
            state.edition_list_index = arr
        },
        set_edition_list:(state, arr) => {
            state.edition_list = arr
        },
        set_book_list_index:(state, arr) => {
            state.book_list_index = arr
        },
        set_book_list:(state, arr) => {
            state.book_list = arr
        },
        set_chapter_list_index:(state, arr) => {
            state.chapter_list_index = arr
        },
        set_chapter_list:(state, arr) => {
            state.chapter_list = arr
        },
        set_node_list_index:(state, arr) => {
            state.node_list_index = arr
        },
        set_node_list:(state, arr) => {
            state.node_list = arr
        },
        set_node_child_list_index:(state, arr) => {
            state.node_child_list_index = arr
        },
        set_node_child_list:(state, arr) => {
            state.node_child_list = arr
        },
        set_isPc:(state, arr) => {
            state.isPc = arr
        },
    },
    actions: {
        async getStageList(context,data){
            // let res = await getStage(data)
            // if(res){
            //     context.commit('set_stage_list', res.data.records);
            // }
            let res = await getStage(data)
            if(res){
                context.commit('set_stage_list',[
                    {
                      "id": 1,
                      "title": "小学",
                      "createTime": "2022-10-28 14:38:01",
                      "updateTime": "2022-10-28 14:38:16"
                    },
                    {
                      "id": 2,
                      "title": "初中",
                      "createTime": "2023-01-05 17:27:24",
                      "updateTime": "2023-01-05 17:27:24"
                    },
                    {
                      "id": 3,
                      "title": "高中",
                      "createTime": "2023-01-05 17:27:33",
                      "updateTime": "2023-01-05 17:27:33"
                    }
                  ]);
            }
        },
        async getGradeList(context,data){
            let res = await getGrade(data)
            if(res){
                if(data.type){
                    context.commit('set_grade_list_index', res.data.gradeList);
                    return
                }
                context.commit('set_grade_list', res.data.gradeList);
            }
        },
        async getSubjectList(context,data){
            let res = await getSubject(data)
            if(res){
                if(data.type){
                    context.commit('set_subject_list_index', res.data);
                    return
                }
                context.commit('set_subject_list', res.data);
            }
        },
        async getBookChapterList(context,data){
            let res = await getBookChapter(data)
            if(res){
                let str = JSON.stringify(res.data).replace(/editionId|bookId|chapterId/gi, "value");
                str = str.replace(/editionTitle|bookTitle|chapterTitle/gi, "label");
                str = str.replace(/bookTreeList|chapterTreeList|child/gi, "children");
                let arr = JSON.parse(str);
                arr = getTreeData(arr);
                if(data.type){
                    context.commit('set_book_chapter_list_index', arr);
                    return
                }
                context.commit('set_book_chapter_list', arr);
            }
        },
        async getKnowledgeList(context,data){
            let res = await getKnowLedgeTree(data)
           

            if(res){
                if(data.type){
                    context.commit('set_knowledge_list_index', res.data);
                    return
                }
                context.commit('set_knowledge_list', res.data);
            }
        },
        async getNewEdition(context,data){
            let res=await getNewEdition(data)
            if(res){
                if(data.type){
                    context.commit('set_edition_list_index', res.data);
                    return
                }
                context.commit('set_edition_list', res.data);
            }
        },
        async getNewBook(context,data){
            let res=await getNewBook(data)
            if(res){
                if(data.type){
                    context.commit('set_book_list_index', res.data);
                    return
                }
                context.commit('set_book_list', res.data);
            }
        },
        async getNewChapter(context,data){
            let res=await getNewChapter(data)
            if(res){
                if(data.type){
                    context.commit('set_chapter_list_index', res.data);
                    return
                }
                context.commit('set_chapter_list', res.data);
            }
        },
        async getNewNode(context,data){
            let res=await getNewNode(data)
            if(res){
                if(data.type){
                    context.commit('set_node_list_index', res.data);
                    return
                }
                context.commit('set_node_list', res.data);
            }
        },
        async getNewNodeChild(context,data){
            let res=await getNewNodeChild(data)
            if(res){
                if(data.type){
                    context.commit('set_node_child_list_index', res.data);
                    return
                }
                context.commit('set_node_child_list', res.data);
            }
        },
        async getNewKnowledge(context,data){
            let res=await getNewKnowledge(data)
            if(res){
                if(data.type){
                    context.commit('set_knowledge_list_index', res.data);
                    return
                }
                 context.commit('set_knowledge_list', res.data);
            }
        }
    },
    modules: {
    }
}
export default user;
