<template>
				<div class="login-box">
					<div v-if="detail_info.currentTeacherInfo">
						<div class="flex_start blue_box ">
						<p class="text_16_bold grey1">当前教师：</p>
						<p>
							<span class="text_14 grey1">{{detail_info.currentTeacherInfo}}</span>
							<!-- <span class="text_14 grey2">（身份证后四位0082）</span> -->
						</p>
					</div>
					<div class="blue_box margin_top_16" v-if="detail_info.boundVoList&&detail_info.boundVoList.length>0">
						<p class="text_16_bold grey1">已绑定：</p>
						<ul>
							<li class="grey1 text_14 margin_top_16" v-for="item in detail_info.boundVoList">
								{{item.gsiName}}
							</li>
						</ul>
					</div>
					<div class="flex_end">
						<div @click="close" class="cancel-login-btn flex_center">
						取消
					</div>
						<div @click="send" class="login-btn flex_center" v-if="showButton">
							确认为本人，新增绑定关系
							
					</div>
					</div>
					</div>
				<div class="form-contain"  v-else>
					
					<el-form ref="ruleForm" :model="form" :rules="rules"   label-position="top" :hide-required-asterisk="true">
						<el-form-item label="身份证号" prop="cardNo">
							<el-input v-model="form.cardNo" maxlength="18"  placeholder="请输入身份证号"  autocomplete="off" >
							</el-input>
						</el-form-item>
						<el-form-item label="手机号（自动带入企微绑定手机，不可修改）" >
							<el-input v-model.number="login_mess.mobile"   placeholder="请输入手机号"  disabled>
							</el-input>
						</el-form-item>
						
						<el-form-item label="验证码" prop="mobileCode">
							<div class="flex_between">
							<el-input  maxlength="6" minlength="4"  v-model="form.mobileCode" placeholder="请输入验证码" 
								>
								</el-input>
							<span class="code-button" @click="getCode">
								{{showCode?'&nbsp;&nbsp;重新发送('+num+')':'&nbsp;&nbsp;发送验证码'}}</span>
						</div>
						</el-form-item>
					</el-form>
					<div class="flex_end">
						<div @click="close" class="cancel-login-btn flex_center">
						取消
					</div>
						<div @click="login('ruleForm')" class="login-btn flex_center">
						确认
					</div>
					</div>

				</div>
		</div>
</template>
<script>
import { mapGetters } from "vuex";
// Toast
import { showToast } from 'vant';
import 'vant/es/toast/style';
import {isTips} from '@/util/index'
import {mobileMessage,passwordReset,userBind,userBindAdd,userBindKey} from '@/api/login'
	export default {
		components: {},
		data() {
			return {
				form: {
					cardNo: "",
					mobileCode:''
				},
				showCode: false,
				num: 60,
				rules: {
					cardNo: [
						{
							required: true,
							message: "请输入身份证号",
							trigger: "blur",
						},
					],
					mobileCode: [
						{
							required: true,
							message: "请输入验证码",
							trigger: "blur",
						},
					],
				},
				timer:null,
				key:'',
				type:'',
				detail_info:{
					"key": null,
					"currentTeacherInfo": null,
					"boundVoList": null
					},
					showButton:true
			};
		},
		computed: {},
		watch: {},
		methods: {
		async send(){
			let data={
				key:this.detail_info.key
			}
			let res=await userBindKey(data)
			if(res){
				this.$message({
						message: "绑定成功！",
						type: "success",
						showClose: true,
						duration:1000
					});
				this.close()
			}
		},
			async userBind(type){
				let res=await userBind()
				if(res){
					this.detail_info=res.data
					if(type=='create'&&this.detail_info.currentTeacherInfo){
						this.showButton=false
					}
				}
			},
			
			async getCode() {
				if (this.login_mess.mobile == "") {
					return;
				}
				if(this.showCode)return
				this.num=60
				let data = {
					mobile: this.login_mess.mobile,
				};
				this.showCode = true;
				let res = await mobileMessage(data);
				if (res) {
					this.showCode = true;
					let timer = setInterval(() => {
						this.num--
						if (this.num == 0) {
							this.showCode = false;
							clearInterval(timer)
						}
					}, 1000);
				}else{
					this.showCode=false
				}
			},
			login(formName) {
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						let data={
							cardNo:this.form.cardNo,
							mobileCode:this.form.mobileCode
						}
						let res=await userBindAdd(data)
						if(res){
							this.detail_info=res.data
							// this.userBind('add')
							// isTips()
							// if(this.isPc){
							// 	this.$emit('close')				
							// }else{
							// 	this.$router.replace('/login')
							// }
							// this.$router.replace('/homepage/index');

						}

					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			close(){
				this.$emit('close')	
			}
		},
		created() {
			this.userBind('create')
		},
		activated() {
			
		},
		computed: {
			...mapGetters(["login_mess", "basic_static",'login_dialog','isPc']),
		},
		mounted() {
		
		},
		destroyed() {
			
		},
	};

</script>
  <style lang="scss" scoped>
  @import "../style/element.scss";
  .blue_box{
	background: #F2F4FF;
border-radius: 12px;
padding: 24px;
  }
  ::v-deep .el-form-item{margin-bottom: 32px;}
    ::v-deep .el-form-item__label{padding: 0 0 8px 0;font-size: 16px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2B2C33;
line-height: 24px;}
.login-box{
	.code-button{
	position: absolute;
    right: 16px;
   cursor: pointer;
   font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #4260FD;
line-height: 20px;
}
	.title{
		font-size: 32px;
font-family: PingFang-SC-Bold, PingFang-SC;
font-weight: bold;
color: #2B2C33;
line-height: 40px;
position: relative;
margin-bottom: 32px;
	}
	.title::after{position: absolute;content: '';width: 62px;
height: 12px;
background: linear-gradient(90deg, #4260FD 0%, #FFFFFF 100%);bottom: -8px;left: 0;}
::v-deep .el-form-item__label{font-size: 16px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2B2C33;
line-height: 24px;}
::v-deep .el-input__inner{
	background: #FFFFFF;
border-radius: 8px;
border: 1px solid #E2E3E6;
height: 48px;
line-height: 48px;
}
.login-btn{
	min-width: 184px;
height: 48px;
background: linear-gradient(90deg, #6F9EFF 0%, #4260FD 100%);
box-shadow: 0px 4px 6px 0px rgba(66,96,253,0.24);
border-radius: 8px;
font-size: 18px;
font-family: PingFang-SC-Bold, PingFang-SC;
font-weight: bold;
color: #FFFFFF;
cursor: pointer;
margin-top: 24px;
padding: 0 24px;
}
.login-btn:hover{opacity: .7;}
.cancel-login-btn{
	width: 96px;
height: 48px;
background: #FFFFFF;
border-radius: 8px;
border: 1px solid #4260FD;
font-weight: bold;
font-size: 18px;
color: #94959C;
margin-right: 16px;
margin-top: 24px;
cursor: pointer;
}
}
</style>